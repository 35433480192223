<template>

	<div>
		<TopNav></TopNav>
		<Header></Header>
			
		<div class="Wrap">
			<div class="UrHere">
				<i class="el-icon-s-home" @click="$Jump('/')" style="cursor: pointer;"> 首页</i> > 资讯中心
			</div>
		</div>
		<div class="Wrap">
		
			
			<div class="NewsList">
				
				<template v-if="NewsList.length > 0">
					<div class="One" v-for="item in NewsList" :key="item.Id">
						<div class="Img" v-if="item.Thumb && item.Thumb.length > 0">
							<el-image :src="item.Thumb"></el-image>
						</div>
						<div class="Msg">
							<li @click="$Jump('/news/info/'+item.Id)">{{item.Title}}</li>
							<p>{{item.CreatedAt}} <em>来自 {{item.CategoryName}}</em></p>
						</div>
					</div>
				</template>
				<div class="Zero" v-else>
					<i class="el-icon-error"></i>该分类下暂无资讯
				</div>
				
				<div class="Page" style="width: 100%;" v-if="NewsList.length > 0">
					<el-pagination
					  background
					  layout="prev, pager, next"
					  :total="Total" :page-size="PageSize" :current-page="Page" @current-change="handleCurrentChange">
					</el-pagination>
				</div>
				
			</div>
			
			<div class="NewsCategory">
				<li :class="CategoryId == '' ? 'On':''" @click="CategoryIdChange('')">全部资讯</li>
				<template v-for="cat in NewsCategoryList">
					<li :class="CategoryId == cat.Id ? 'On':''" @click="CategoryIdChange(cat.Id)" v-if="cat.Id != '655df3db-66c6-48b1-8430-5f79f3757eae' && cat.Id != '864d1853-e2a5-450f-a582-8d9ca05f486d'" :key="cat.Id">{{cat.Name}}</li>
				</template>
			</div>
			
		</div>
		
	
		<Footer></Footer>
	</div>
	
</template>

<script>
	import {Image,Pagination} from 'element-ui'
	export default {
	  name: 'NewsList',
	  data() {
	      return {
			  NewsList:[],
			  Total:0,
			  PageSize:10,
			  Page:1,
			  NewsCategoryList:[],
			  CategoryId:'',
	      }
	    },
	  components: {
		  'el-image':Image,
		  'el-pagination':Pagination
	  },
	  created() {
		this.GetCategoryList()
		if(this.$route.params.CategoryId == 'all'){
			this.CategoryIdChange('')
		}else{
			this.CategoryIdChange(this.$route.params.CategoryId)
		}
	  },
	  methods:{
		  CategoryIdChange(_v){
			  this.CategoryId = _v
			  this.GetList(1)
		  },
		  GetList(_page){
			let data = {
				Service:'News',
				Class: 'News',
				Action: 'List',
				Page:_page,
				PageSize:this.PageSize,
				CategoryId:this.CategoryId,
				JumpCategoryIds:JSON.stringify(['655df3db-66c6-48b1-8430-5f79f3757eae','864d1853-e2a5-450f-a582-8d9ca05f486d']), //跳过平台指引和集采公告
			}
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
							
				if(res.ErrorId != 0){
					this.$message({ message: res.Msg, type: 'error'});
					return
				}
				if(res.ErrorId != 0){this.$message(res.Msg);return;}
				if(res.Data.NewsList && res.Data.NewsList.length > 0){
					this.NewsList = res.Data.NewsList
				}else{
					this.NewsList = []
				}
				this.Page = res.Data.CurrentPage
				this.Total = res.Data.Total
				
			})
		  },
		  handleCurrentChange(e){
			  this.GetList(e)
		  },
		  GetCategoryList(){
			let data = {
				Service:'News',
				Class: 'NewsCategory',
				Action: 'List',
				Page:1,
				PageSize:10,
			}
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
							
				if(res.ErrorId != 0){
					this.$message({ message: res.Msg, type: 'error'});
					return
				}
				if(res.ErrorId != 0){this.$message(res.Msg);return;}
				this.NewsCategoryList = res.Data.NewsCategoryList
				
			})
		  }
	  }
	}
</script>

<style scoped>
.UrHere{
	margin: 20px 0px;
}
.NewsList{
	flex: 1;
	margin-right: 40px;
	background-color: #FFFFFF;
	margin-bottom: 40px;
}
.NewsList .One{
	display: flex;
	border-bottom: 10px solid rgba(0,0,0,0.04);
	padding: 20px;
}
.NewsList .One .Img,.NewsList .One .Msg{
	flex: 1;
}
.NewsList .One .Img{

}
.NewsList .Msg{
		margin-left: 20px;
}
.NewsList .Msg li{
	font-size: 1.2rem;
	cursor: pointer;
}
.NewsList .Msg p{
		color: rgba(0,0,0,0.4);
}
.NewsList .Msg p em{
	float: right;
}
.NewsCategory{
	padding: 20px;
	background-color: #FFFFFF;
		margin-bottom: 40px;
}
.NewsList .Zero{
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
}
.NewsCategory li{
	padding: 10px 30px;
	border-bottom: 1px solid rgba(0,0,0,0.04);
	cursor: pointer;
}
.NewsCategory li:hover,.NewsCategory li.On{
	color: rgba(228,0,0,1);
}
</style>
